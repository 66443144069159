import React from "react"
import ReactExport from "react-data-export";
import { commonViewColumns, whereUsedColumns, sbmCommonViewsCols } from "../../constant/Constants";

function ExportToExcel(props) {
    const dataCSV = props.data
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const userType = localStorage.getItem("userType")
    const view = sessionStorage.getItem("view")

    const columns = props.view !== 'Search' ? (view === 'SBM' ? sbmCommonViewsCols : commonViewColumns) : whereUsedColumns


    const flattenedData = dataCSV.reduce((acc, record) => {
        if (record.ImpactedParts.length === 0 && userType === "PG") {
            acc.push({
                Name: record.Name,
                Type: record.Type,
                SpecSubType: record.SpecSubType,
                AffRev: record.Rev,
                AffTitle: record.Title,
                ReasonForChange: record.ReasonForChange,
                ReleaseDate: record.ReleaseDate,
                EBPModified: record.EBPModified,
                'Impacted Part': "",
                'Display Type': "",
                'Rev': "",
                'Title': ""
            });
        } else if (record.ImpactedParts.length === 0 && userType === 'SBM' && view === 'SBM') {
            acc.push({
                Name: record.Name,
                Type: record.Type,
                SpecSubType: record.SpecSubType,
                AffRev: record.Rev,
                AffTitle: record.Title,
                ReasonForChange: record.ReasonForChange,
                ReleaseDate: record.ReleaseDate,
                EBPModified: record.EBPModified
            });

        }
        if (record.ImpactedParts.length !== 0 && userType === "PG") {
            var impactedParts = props.view !== 'Search' ? record.ImpactedParts.slice(0, 5) : record.ImpactedParts
            impactedParts.forEach(element => {
                acc.push({
                    Name: record.Name,
                    Type: record.Type,
                    SpecSubType: record.SpecSubType,
                    AffRev: record.Rev,
                    AffTitle: record.Title,
                    ReasonForChange: record.ReasonForChange,
                    ReleaseDate: record.ReleaseDate,
                    EBPModified: record.EBPModified,
                    'Impacted Part': element.Name,
                    'Display Type': element.DisplayType,
                    'Revision': element.Revision,
                    'ImpactedTitle': element.Title
                });
            });
        }
        return acc
    }, []);

    return (
        <ExcelFile element={<span>Export All Results</span>} filename={"Specifications"}>
            <ExcelSheet data={flattenedData} name="Specifications">
                {columns.map((col, index) => (
                    <ExcelColumn key={index} label={col.label} value={col.value} />
                ))}
            </ExcelSheet>
        </ExcelFile>
    );
}

export default ExportToExcel