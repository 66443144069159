import React, { useEffect } from "react"
import { Button } from "react-bootstrap"
import logo from "../../resource/img/PGLogo.png"

function Redirect(props) {
   function handleLogInPing() {
      localStorage.setItem("userType", "PG")
      let authUrl =
         `${process.env.REACT_APP_AUTH_URI}/as/authorization.oauth2?` +
         `response_type=${process.env.REACT_APP_responseType}&` +
         `client_id=${process.env.REACT_APP_clientId}&` +
         `scope=${process.env.REACT_APP_scope}&` +
         `display_name=${process.env.REACT_APP_display_name}&` +
         `redirect_uri=${process.env.REACT_APP_redirectUri}` +
         `&state=/tokens/authcode`

      window.location.href = authUrl
   }

   function handleLogInJagger() {
      localStorage.setItem("userType", "SBM")
      let authUrl =
         `${process.env.REACT_APP_JAGGER_AUTH_URI}?` +
         `response_type=${process.env.REACT_APP_responseType}&` +
         `scope=${process.env.REACT_APP_JAGGER_SCOPE}&` +
         `client_id=${process.env.REACT_APP_JAGGER_CLIENTID}&` +
         `redirect_uri=${process.env.REACT_APP_redirectUri}`
      window.location.href = authUrl
   }

   useEffect(() => {
      let gcas = ""
      if (
         localStorage.getItem("calledFrom") &&
         localStorage.getItem("calledFrom") === "gcas"
      ) {
         gcas = localStorage.getItem("gcas")
      }
      localStorage.removeItem("id_token")
      localStorage.removeItem("User")
      localStorage.clear()
      sessionStorage.clear()
      localStorage.setItem("gcas", gcas)
   }, [])

   return (
      <React.Fragment>
         <div className="signInContainer">
            <div className="signInBox">
               <div className="signInImageContainer">
                  <img src={logo} className="signInImage" />
               </div>
               <h2 className="signInText">Sign in to SpecReader</h2>
               <Button
                  className="signInButton"
                  style={{ marginTop: "20%" }}
                  onClick={handleLogInPing}
               >
                  Sign In with PINGID
               </Button>
               <Button
                  className="signInButton"
                  style={{ marginTop: "7vh" }}
                  onClick={handleLogInJagger}
               >
                  Sign In with JAGGAER (SBM)
               </Button>
            </div>
         </div>
      </React.Fragment>
   )
}

export default Redirect
