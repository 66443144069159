import React, { useEffect, useState } from "react"
import { Button, ButtonGroup, Dropdown, Form } from "react-bootstrap"
import { withRouter } from "react-router-dom"
import { logout, EmailPreference } from "../../api/AuthService"
import {
   FETCH_VOICEIT,
   FETCH_SUBMITTICKET,
   FETCH_CADVIEWER,
   EMAIL_PREFERENCE,
} from "../../constant/Constants"

function Logout(props) {
   const UserName = localStorage.getItem("User")
   const UserId = localStorage.getItem("UserId")
   const userRole = localStorage.getItem("role")
   const employee = localStorage.getItem("employee")
   const userType = localStorage.getItem("userType")
   const preference = JSON.parse(sessionStorage.getItem("emailNotifications"))
   const [emailNotifications, setEmailNotifications] = useState(preference)

   useEffect(() => {
      sessionStorage.setItem("emailNotifications", JSON.stringify(emailNotifications))
   }, [emailNotifications])

   const toggleEmailNotifications = () => {
      setEmailNotifications((prev) => !prev)
      sendEmailPreference(!emailNotifications)
   }

   function sendEmailPreference(data) {
      EmailPreference(`${EMAIL_PREFERENCE}/${UserId}/${data}`)
         .then((response) => {})
         .catch((error) => {
            if (error.response && error.response.status) {
               console.log(error.response.status, error.response.data.message)
            } else {
               console.log(1000, error)
            }
         })
   }

   function handleClick() {
      logout(props.history)
   }
   function handleVoiceIT() {
      window.open(`${FETCH_VOICEIT}`)
   }
   function handleSubmitTickets() {
      window.open(`${FETCH_SUBMITTICKET}`)
   }
   function handleCADViewer() {
      window.open(`${FETCH_CADVIEWER}`)
   }
   function openExternalView() {
      sessionStorage.removeItem("breadCrumb")
      sessionStorage.setItem("view","PG")
      props.history.push("/vendorInfo")
   }
   function openExternalViewSBM() {
      sessionStorage.removeItem("breadCrumb")
      sessionStorage.setItem("view", "SBM")
      props.history.push("/vendorInfo")
   }
   const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
         href=""
         ref={ref}
         onClick={(e) => {
            e.preventDefault()
            onClick(e)
         }}
      >
         {children}
         &#x25bc;
      </a>
   ))

   return (
      <React.Fragment>
         <div className="userIconStyle">
            {UserName && (
               <span>
                  Welcome &nbsp;{UserName}
                  {userRole != '' ? (
                     <span>
                        &nbsp;|&nbsp;
                        <Button
                           className="logoutBtnText"
                           variant="link"
                           onClick={userType === 'PG' ? openExternalView : openExternalViewSBM}
                        >
                           {userType === 'SBM' ? 'External Views - SBM' : 'External Views'}
                        </Button>
                     </span>
                  ) : (employee == "PG" ? (<span>
                     &nbsp;|&nbsp;
                     <Dropdown
                        as={ButtonGroup}
                        className="logoutBtnText"
                        id="actionsDropdown"
                     >
                        <Dropdown.Toggle
                           as={CustomToggle}
                           id="dropdown-custom-components"
                        >
                           Views
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <Dropdown.Item
                              onClick={openExternalView}
                              className="dropdownActionItem"
                           >
                              External Views - Enovia
                           </Dropdown.Item>
 
                           <Dropdown.Item
                              onClick={openExternalViewSBM}
                              className="dropdownActionItem"
                           >
                              External Views - SBM
                           </Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                  </span>) : ""
                  )}
                  &nbsp;|&nbsp;
                  {
                     userType !== 'SBM' && (
                        <>
                           <span>
                              <Dropdown
                                 as={ButtonGroup}
                                 className="logoutBtnText"
                                 id="actionsDropdown"
                              >
                                 <Dropdown.Toggle
                                    as={CustomToggle}
                                    id="dropdown-custom-components"
                                 >
                                    Actions
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Dropdown.Item
                                       href="/DsmReport"
                                       className="dropdownActionItem"
                                    >
                                       DSM Reports
                                    </Dropdown.Item>
                                    {userRole !== "CM" && userRole !== "SP" && (
                                       <Dropdown.Item
                                          href="/Subscription"
                                          className="dropdownActionItem"
                                       >
                                          Dynamic Subscription
                                       </Dropdown.Item>
                                    )}
                                    {userRole !== "CM" && userRole !== "SP" && employee === "PG" && (
                                       <Dropdown.Item
                                          className="dropdownActionItem"
                                          onClick={props.handleVendorModal}
                                       >
                                          Preferences
                                       </Dropdown.Item>
                                    )}
                                    {employee !== "PG Staff Augmentation" && userType !== "SBM" && (
                                       <Form className="dropdownActionItem">
                                          <div className="custom-switch-container">
                                             <label htmlFor="emailswitch" className="switch-label">
                                                EBP View Notifications
                                             </label>
                                             <Form.Check
                                                type="switch"
                                                id="emailswitch"
                                                checked={emailNotifications}
                                                onChange={toggleEmailNotifications}
                                             />
                                          </div>
                                       </Form>
                                    )}
                                 </Dropdown.Menu>
                              </Dropdown>
                           </span>
                           &nbsp;|&nbsp;
                        </>
                     )
                  }
                  <span>
                     <Dropdown
                        as={ButtonGroup}
                        className="logoutBtnText"
                        id="dropdownAction"
                     >
                        <Dropdown.Toggle
                           as={CustomToggle}
                           id="dropdown-custom-components"
                        >
                           Help
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <Dropdown.Item
                              className="dropdownActionItem"
                              onClick={handleVoiceIT}
                           >
                              VoiceIt
                           </Dropdown.Item>
                           <Dropdown.Item
                              className="dropdownActionItem"
                              onClick={handleSubmitTickets}
                           >
                              Submit Ticket
                           </Dropdown.Item>
                           <Dropdown.Item
                              className="dropdownActionItem"
                              onClick={handleCADViewer}
                           >
                              ArtiosCAD Viewer
                           </Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                  </span>
                  &nbsp;|&nbsp;
                  <Button className="logoutBtnText" variant="link" onClick={handleClick}>
                     Logout
                  </Button>
               </span>
            )}
         </div>
      </React.Fragment>
   )
}
export default withRouter(Logout)
