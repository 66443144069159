import React, { useEffect, useState } from "react"
import { Button, Form, Table, Tooltip, OverlayTrigger, Modal, CloseButton } from "react-bootstrap"
import { Link } from "react-router-dom"
import { addBreadCrumbLinks } from "../commonComponents/EventHandler"
import { isLoggedIn, fetchAPIData } from "../../api/AuthService"
import { FETCH_WHAT_CHANGED } from "../../constant/Constants"

const CommonView = (props) => {
   const [show, setShow] = useState(false);
   const [actionTakenIn, setActionTakenIn] = useState("");
   const [actionTakenBy, setActionTakenBy] = useState("");
   const [actionTakenOn, setActionTakenOn] = useState("");
   const [error, setError] = useState(0)
   const [whatChangedData, setWhatChangedData] = useState([]);
   const [loading, setLoading] = useState(false)
   const [whatChanged,setWhatChanged] = useState(false)


   const actionKey = props.currentResult.length !== 0 ? "Action Details" : "";
   const userType = localStorage.getItem("userType")
   const view = sessionStorage.getItem("view")

   const handleClose = () => {
      setActionTakenIn("")
      setActionTakenBy("")
      setActionTakenOn("")
      setShow(false)
   }

   const handleShow = (actiontakenin, actiontakenon, actiontakenby) => {
      setActionTakenIn(actiontakenin)
      setActionTakenBy(actiontakenby)
      setActionTakenOn(actiontakenon)
      setShow(true)
   };
   const fetchData = async (ebpId) => {
      try {
         setLoading(true)
         const response = await fetchAPIData(`${FETCH_WHAT_CHANGED}/${ebpId}`)
         if (response.data && response.data.length !== 0) {
            setError(0)
            const data=response.data
            setWhatChangedData(response.data)
            setLoading(false)
         } 
         else{
            setError(0)
            setLoading(false)
         }
      } catch (error) {
         setError(1)
         setLoading(false)
         console.log(error)
      }
   }

   const handleWhatChanged = (ebpId) =>{
      fetchData(ebpId)
      setWhatChanged(true)
   }

   const handleWhatClose = () => {
      setWhatChanged(false)
   }

   const childDataLink = (child) => {
      return child.link != null && child.Id != null ? (
         <Link
            target="_blank"
            rel="noreferrer noopener"
            onClick={() =>
               addBreadCrumbLinks(
                  child.Name,
                  `/${child.link.toUpperCase().trim()}/${child.Id.trim()}`
               )
            }
            to={(location) => ({
               ...location,
               pathname: `/${child.link.toUpperCase()}/${child.Id}`,
            })}
         >
            <span textalign="left">{child.Name}</span>
         </Link>
      ) : (
         <span>{child.Name}</span>
      )
   }

   const tooltip = (
      <Tooltip id="tooltip">
         All Impacted Parts might not be listed in this View. Please click on the
         hyperlink to view if there are more Impacted Parts.
      </Tooltip>
   )

   function checkViewMoreParts(key) {
      if (key.viewMoreParts) {
         return (
            <td align="center" colSpan={5}>
               <OverlayTrigger placement="top" overlay={tooltip}>
                  <Button
                     style={{
                        color: "#007bff",
                        backgroundColor: "white",
                        border: "none",
                     }}
                     onClick={() => props.handleViewMorePart(key.Name)}
                  >
                     View more Impacted Parts
                  </Button>
               </OverlayTrigger>
            </td>
         )
      } else {
         return (
            <>
               <td align="left" width="10%">
                  { }
               </td>
               <td align="left" width="10%">
                  { }
               </td>
               <td align="left" width="10%">
                  { }
               </td>
               <td align="left" width="10%">
                  { }
               </td>
               {props.view == "Pending" && (
                  <td align="left" width="5%">
                     { }
                  </td>
               )}
            </>
         )
      }
   }

   const childData = (childCount, key, index) => {
      let rowSpan = childCount
      if (childCount > 5) {
         rowSpan = 6
      } else if (key.viewMoreParts) {
         rowSpan += 1
      }
      return key["ImpactedParts"].map((childRow, childIndex) => {
         if (childIndex == 0) {
            return (
               <>
                  <tr key={childIndex}>
                     <td align="center" width="5%" rowSpan={rowSpan}>
                        {" "}
                        <Form.Check
                           type="checkbox"
                           name="tableCheckbox"
                           checked={props.isChecked(key.ebpId)}
                           onChange={() =>
                              props.handleRowCheckBoxChange(key.ebpId, key.Name, key.Id)
                           }
                        />
                     </td>
                     <td width="10%" rowSpan={rowSpan}>
                        {key.link != null && key.Id != null ? (
                           <Link
                              target="_blank"
                              rel="noreferrer noopener"
                              onClick={() =>
                                 addBreadCrumbLinks(
                                    key.Name,
                                    `/${key.link.toUpperCase().trim()}/${key.Id.trim()}`
                                 )
                              }
                              to={(location) => ({
                                 ...location,
                                 pathname: `/${key.link.toUpperCase()}/${key.Id}`,
                              })}
                           >
                              <span textalign="left">{key.Name}</span>
                           </Link>
                        ) : (
                           <span>{key.Name}</span>
                        )}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.Type}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.SpecSubType}
                     </td>
                     <td align="left" width="5%" rowSpan={rowSpan}>
                        {key.Rev}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.Title}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.ReasonForChange}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.ReleaseDate}
                     </td>
                     <td align="left" width="10%" rowSpan={rowSpan}>
                        {key.EBPModified}
                     </td>
                     {(props.view === "Accepted" || props.view === "Rejected") &&
                        <td align="left" width="10%" color="blue" rowSpan={rowSpan}>
                           {view === "PG" ?
                              (<span
                                 onClick={() => handleShow(key["actionTakenIn"], key["actionTakenOn"], key["actionTakenBy"])}
                                 className="actionDetails"
                              >{actionKey}
                              </span>)
                              : (<span>{key.actionTakenOn}</span>)}
                        </td>}
                     {props.view == "Pending" && (
                        <td align="left" width="5%" rowSpan={rowSpan}>
                           {
                              <Button
                                 className="fa fa-download"
                                 aria-hidden="true"
                                 variant="link"
                                 disabled={props.pdfDisable}
                                 onClick={() =>
                                    props.handlePDFDownload(
                                       key.Name,
                                       key.link,
                                       key.Id,
                                       key.Rev
                                    )
                                 }
                              ></Button>
                           }
                        </td>
                     )}
                     {props.view=== "Pending" && view ==="SBM" && 
                        (
                        <td align="left" width="10%" color="blue" rowSpan={rowSpan}>
                           <span 
                              onClick = {()=>handleWhatChanged(key.ebpId)} 
                              className = "actionDetails"
                           >
                              {key.isViewChanged ? "What Changed" : " "}
                           </span>
                        </td>)
                     }
                     <td align="left" width="10%">
                        {childDataLink(childRow)}
                     </td>
                     <td align="left" width="10%">
                        {childRow["DisplayType"]}
                     </td>
                     <td align="left" width="5%">
                        {childRow["Revision"]}
                     </td>
                     <td align="left" width="10%">
                        {childRow["Title"]}
                     </td>
                     {props.view == "Pending" && (
                        <td align="left" width="5%">
                           {
                              <Button
                                 className="fa fa-download"
                                 aria-hidden="true"
                                 variant="link"
                                 disabled={props.pdfDisable}
                                 onClick={() =>
                                    props.handlePDFDownload(
                                       childRow.Name,
                                       childRow.link,
                                       childRow.Id,
                                       childRow.Revision
                                    )
                                 }
                              ></Button>
                           }
                        </td>
                     )}
                  </tr>
                  {childIndex == childCount - 1 && key.viewMoreParts && (
                     <tr key={childCount}>
                        <td align="center" colSpan={5}>
                           <OverlayTrigger placement="top" overlay={tooltip}>
                              <Button
                                 style={{
                                    color: "#007bff",
                                    backgroundColor: "white",
                                    border: "none",
                                 }}
                                 onClick={() => props.handleViewMorePart(key.Name)}
                              >
                                 View more Impacted Parts
                              </Button>
                           </OverlayTrigger>
                        </td>
                     </tr>
                  )}
               </>
            )
         } else if (childIndex > 0 && childIndex < 5) {
            return (
               <>
                  <tr key={childIndex}>
                     <td align="left" width="10%">
                        {childDataLink(childRow)}
                     </td>
                     <td align="left" width="10%">
                        {childRow["DisplayType"]}
                     </td>
                     <td align="left" width="5%">
                        {childRow["Revision"]}
                     </td>
                     <td align="left" width="10%">
                        {childRow["Title"]}
                     </td>
                     {props.view == "Pending" && (
                        <td align="left" width="5%">
                           {
                              <Button
                                 className="fa fa-download"
                                 aria-hidden="true"
                                 variant="link"
                                 disabled={props.pdfDisable}
                                 onClick={() =>
                                    props.handlePDFDownload(
                                       childRow.Name,
                                       childRow.link,
                                       childRow.Id,
                                       childRow.Revision
                                    )
                                 }
                              ></Button>
                           }
                        </td>
                     )}
                  </tr>
                  {childIndex == childCount - 1 && key.viewMoreParts && (
                     <tr key={childCount}>
                        <td align="center" colSpan={5}>
                           <OverlayTrigger placement="top" overlay={tooltip}>
                              <Button
                                 style={{
                                    color: "#007bff",
                                    backgroundColor: "white",
                                    border: "none",
                                 }}
                                 onClick={() => props.handleViewMorePart(key.Name)}
                              >
                                 View more Impacted Parts
                              </Button>
                           </OverlayTrigger>
                        </td>
                     </tr>
                  )}
               </>
            )
         } else if (childIndex == 5) {
            return (
               <tr key={childIndex}>
                  <td align="center" colSpan={5}>
                     <OverlayTrigger placement="top" overlay={tooltip}>
                        <Button
                           style={{
                              color: "#007bff",
                              backgroundColor: "white",
                              border: "none",
                           }}
                           onClick={() => props.handleViewMorePart(key.Name)}
                        >
                           View more Impacted Parts
                        </Button>
                     </OverlayTrigger>
                  </td>
               </tr>
            )
         }
      })
   }

   const info_What_Changed_content = whatChangedData.map((item)=>{
      return(
      
      <tr>
        <td> {item.link != null ? (  <Link
                              target="_blank"
                              rel="noreferrer noopener"
                              onClick={() =>
                                 addBreadCrumbLinks(
                                    item.Name,
                                    `/${item.link.toUpperCase().trim()}/${item.Id.trim()}`
                                 )
                              }
                              to={(location) => ({
                                 ...location,
                                 pathname: `/${item.link.toUpperCase()}/${item.Id}`,
                              })}
                           >
                              <span textalign="center">{item.Name}</span>
                           </Link>
                        ) : (
                           <span>{item.Name}</span>
                        )}
                        </td>
                        <td>{item.DisplayType}</td>
                        <td>{item.Revision}</td>               
                        <td>{item.Title}</td>
                        <td>{item.ReasonForChange}</td>
                     </tr>
                  )
               })


   const info_content = props.currentResult.map((key, index) => {
      let childCount = key["ImpactedParts"].length
      if (childCount == 0) {
         return (
            <tr key={index}>
               <td align="center" width="5%">
                  {" "}
                  <Form.Check
                     type="checkbox"
                     name="tableCheckbox"
                     checked={props.isChecked(key.ebpId)}
                     onChange={() => props.handleRowCheckBoxChange(key.ebpId, key.Name, key.Id)}
                  />
               </td>
               <td width="10%">
                  {key.link != null ? (
                     <Link
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() =>
                           addBreadCrumbLinks(
                              key.Name,
                              `/${key.link.toUpperCase().trim()}/${key.Id.trim()}`
                           )
                        }
                        to={(location) => ({
                           ...location,
                           pathname: `/${key.link.toUpperCase()}/${key.Id}`,
                        })}
                     >
                        <span textalign="left">{key.Name}</span>
                     </Link>
                  ) : (
                     <span>{key.Name}</span>
                  )}
               </td>
               <td align="left" width="10%">
                  {key.Type}
               </td>
               <td align="left" width="10%">
                  {key.SpecSubType}
               </td>
               <td align="left" width="5%">
                  {key.Rev}
               </td>
               <td align="left" width="10%">
                  {key.Title}
               </td>
               <td align="left" width="10%">
                  {key.ReasonForChange}
               </td>
               <td align="left" width="10%">
                  {key.ReleaseDate}
               </td>
               <td align="left" width="10%">
                  {key.EBPModified}
               </td>
               {(props.view === "Accepted" || props.view === "Rejected") &&
                  <td align="left" width="10%" color="blue">
                     {view === "PG" ?
                        (<span onClick={() => handleShow(key["actionTakenIn"], key["actionTakenOn"], key["actionTakenBy"])}
                           className="actionDetails">{actionKey}</span>)
                        : (<span>{key.actionTakenOn}</span>)}
                  </td>}
               {props.view == "Pending" && (
                  <td align="left" width="5%">
                     {
                        <Button
                           className="fa fa-download"
                           aria-hidden="true"
                           variant="link"
                           disabled={props.pdfDisable}
                           onClick={() =>
                              props.handlePDFDownload(key.Name, key.link, key.Id, key.Rev)
                           }
                        ></Button>
                     }
                  </td>
               )}
               {props.view === "Pending" && view === "SBM" && 
                  (
                  <td align="left" width="10%" color="blue">
                     <span 
                        onClick = {()=>handleWhatChanged(key.ebpId)} 
                        className = "actionDetails"
                     >
                        {key.isViewChanged ? "What Changed" : " "}
                     </span>
                  </td>
                  )}
               {userType !== 'SBM' && view !== 'SBM' && checkViewMoreParts(key)}
            </tr>
         )
      } else if (userType !== 'SBM' && view !== 'SBM') return childData(childCount, key, index)
   })

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         props.requestSort("EBPModified")
      }
   }, [])

   return (
      !props.fromViewMore && (
         <div className="vendorTable">
            <Table bordered id="vendorInfoTable">
               <thead id="infoTab">
                  <tr>
                     <th width="5%">
                        <Form.Check
                           type="checkbox"
                           style={{ textAlign: "center" }}
                           id="gridViewCheckbox"
                           onChange={props.handleHeaderCheckBoxChange}
                           checked={props.isChecked("All")}
                        />
                     </th>
                     <th width="10%">
                        Name&nbsp;
                        <i
                           onClick={() => props.requestSort("Name")}
                           className={props.getClassNamesFor("Name")}
                        ></i>
                     </th>
                     <th width="10%">
                        Type &nbsp;
                        <i
                           onClick={() => props.requestSort("Type")}
                           className={props.getClassNamesFor("Type")}
                        ></i>
                     </th>
                     <th width="10%">Spec Sub Type &nbsp;</th>
                     <th width="5%">Rev &nbsp;</th>
                     <th width="10%">Title &nbsp;</th>
                     <th width="10%">Reason For Change &nbsp;</th>
                     <th width="10%">
                        Release Date &nbsp;
                        <i
                           onClick={() => props.requestSort("ReleaseDate")}
                           className={props.getClassNamesFor("ReleaseDate")}
                        ></i>
                     </th>
                     <th width="10%">
                        Modified Date &nbsp;
                        <i
                           onClick={() => props.requestSort("EBPModified")}
                           className={props.getClassNamesFor("EBPModified")}
                        ></i>
                     </th>
                     {(props.view == "Accepted" || props.view == "Rejected") && <th width="10%">{view !== "SBM" ? "Action Details" : "Action Taken On"} &nbsp; &nbsp;</th>}
                     {props.view == "Pending" && <th width="5%"></th>}
                     {(props.view === "Pending" && view === "SBM") && <th width="10%"></th>}
                     {userType !== 'SBM' && view !== 'SBM' && (
                        <>
                           <th width="10%">Impacted Part(s) &nbsp;</th>
                           <th width="10%">Type &nbsp;</th>
                           <th width="5%">Rev &nbsp;</th>
                           <th width="10%">Title &nbsp;</th>
                        </>
                     )}
                     {props.view == "Pending" && userType !== 'SBM' && view !== 'SBM' && <th width="5%"></th>}
                  </tr>
               </thead>
               <tbody id="infoTab">{info_content}</tbody>
            </Table>
            {props.currentResult.length === 0 && props.searchInput === undefined && (
               <p style={{ marginTop: "4px", fontWeight: "bold" }} className={"alertMsg"}>
                  No Records to show
               </p>
            )}
            {props.searchInput !== "" &&
               props.searching &&
               props.dataSearched.length === 0 && (
                  <p
                     style={{ marginTop: "4px", fontWeight: "bold" }}
                     className={"alertMsg"}
                  >
                     No results found matching the text
                  </p>
               )}
            <div>
               <Modal show={show} onHide={handleClose} backdrop="static">
                  <Modal.Header className="CommonModal" >
                     <Modal.Title style={{ fontWeight: "bolder" }}  >Action Details</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <Table bordered>
                        <thead className="CommonModalBodyHead" >
                           <tr style={{ textAlign: "center" }}>
                              <th style={{ textAlign: "center" }} >Action Taken In</th>
                              {actionTakenIn == "Spec Reader" && <th style={{ textAlign: "center" }}>Action Taken By</th>}
                              <th style={{ textAlign: "center" }}>Action Taken On</th>
                           </tr>
                        </thead>
                        <tbody className="CommonModalBodyBody">
                           <tr>
                              <td style={{ textAlign: "center" }}>{actionTakenIn}</td>
                              {actionTakenIn == "Spec Reader" && <td style={{ textAlign: "center" }}>{actionTakenBy}</td>}
                              <td style={{ textAlign: "center" }}>{actionTakenOn}</td>
                           </tr>
                        </tbody>
                     </Table>
                  </Modal.Body>
                  <Modal.Footer>
                     <Button style={{ backgroundColor: "#007bff" }} variant="secondary" onClick={handleClose} className="CommonModalFooter">
                        Close
                     </Button>
                  </Modal.Footer>
               </Modal>
            </div>
            <div>  
              <Modal show={whatChanged} onHide={handleWhatClose}  >
                 {loading ? (
                  <h1 style={{ textAlign: "center", color: "#0173d4" }}>
                     <i
                        className="fa fa-spinner fa-spin"
                        style={{ marginRight: "5px" }}
                     />
                        Loading........
                  </h1>
                  ) :(
                     <Modal.Body >
                        { error === 0 ?(
                           <>
                           <CloseButton style={{fontSize:30,marginBottom:"20px"}} onClick={handleWhatClose} />
                           <Table responsive="lg" bordered>
                              <thead className="CommonModalBodyHead" >
                                 <tr style={{fontSize:"13px",fontFamily:"Century Gothic"}}>
                                    <th >Name</th>
                                    <th >Type</th>
                                    <th >Rev</th>
                                    <th >Title</th>
                                    <th >Reason For Change</th>
                                 </tr>
                              </thead>
                              <tbody className="CommonModalBodyBody">
                                 {info_What_Changed_content}
                              </tbody>
                           </Table>
                           </>
                    ) :(
                  <div
                     className="modal show"
                     style={{ display: 'block', position: 'initial' }}
                  >
                  <Modal.Body >
                     <CloseButton style={{fontSize:30}} onClick={handleWhatClose} />           
                     <div style={{marginTop:"5px",color: "#a00505", fontSize:"15px", fontWeight:"700"}}>Search service issue. Please contact SpecReaderExt.im@pg.com for help.</div>
                  </Modal.Body>
                  </div>
                  )
               }
            </Modal.Body>
            )}
         </Modal>
      </div>
   </div>
   )
)
}

export default CommonView
